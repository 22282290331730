<template>
  <div>
    <px-capa :banner-capa="headerMaterial">
      <template v-slot:title>
        Produtos de limpeza para sua empresa
      </template>
      <template v-slot:text>
        Cadastre-se e compre os produtos que sua empresa precisa
      </template>
    </px-capa>
    <v-container class="mkt-content-container mt-5">
      <px-home-page-block
        show-link
        link="/q/d/17"
        class="mt-5"
        :query="query"
        title="Conheça nossos produtos"
        :qtd-ofertas="20"/>
      <px-landing-page-whatsapp class="mt-5 mb-12"/>
      <px-landing-page-banners class="mt-12"
                               :banners="banners"/>
      <px-home-page-block
        show-link
        link="/q/g/82"
        class="mt-10"
        :query="queryProdutosLimpeza"
        title="Produtos de Limpeza"
        :qtd-ofertas="30"/>
      <v-row dense no-gutters class="mt-5">
        <v-col cols="6" class="d-flex align-center px-2"
               v-for="(banner, index) in bannersDuplos" :key="index">
          <px-home-banner-card :title="banner.titulo"
                               :link="banner.link"
                               :banner-image="banner.foto"
                               gradient="none"
                               :max-height="height"
                               :min-height="height"/>
        </v-col>
      </v-row>
      <px-home-page-block
        show-link
        link="/q/g/134"
        class="mt-12"
        :query="queryPapel"
        title="Papéis Higiênicos e Toalhas"
        :qtd-ofertas="30"/>
      <px-banner-cadastro-email class="mt-5 mb-12"
                                :is-link="false"
                                :text="bannerCadastroText"
                                @click="onClickBannerCadastro"/>
      <px-vantagens class="mt-12 mb-12"/>
      <px-como-funciona class="mt-12 mb-12"/>
      <px-faq-landing-page class="mt-12 mb-10"/>
      <div class="headline font-weight-bold ml-2 mb-5">
        Precisa falar conosco?
      </div>
      <v-row class="mt-10">
        <v-col cols="12">
          <px-rd-duvida-form-subscription/>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import gridMixin from '@/mixins/grid-mixin';
import HomePageBlock from '@/modules/home-page/HomePageBlock.vue';
import HomeBannerCard from '@/modules/home-page/HomeBannerCard.vue';
import LandingPageBanners from '@/modules/landing-page/LandingPageBanners.vue';
import Capa from '@/modules/landing-page/Capa.vue';
import Vantagens from '@/modules/institucional/Vantagens.vue';
import ComoFunciona from '@/modules/institucional/ComoFunciona.vue';
import FaqLandingPage from '@/modules/landing-page/FaqLandingPage.vue';
import RdDuvidaFormSubscription from '@/modules/integration/rd/RdDuvidaFormSubscription.vue';
import PxBannerCadastroEmail from '@/components/marketing/PxBannerCadastroEmail.vue';
import LandingPageWhatsapp from './LandingPageWhatsapp.vue';
import headerMaterial from '../../assets/landing-page/produtos-limpeza/header-produtos-limpeza.png';
import bannerDuploAlcool from '../../assets/landing-page/produtos-limpeza/alcool.png';
import bannerDuploAlcoolMobile from '../../assets/landing-page/produtos-limpeza/alcool-mobile.png';
import bannerDuploAguaSanitaria from '../../assets/landing-page/produtos-limpeza/agua-sanitaria-duplo.png';
import bannerDuploAguaSanitariaMobile from '../../assets/landing-page/produtos-limpeza/agua-sanitaria-mobile.png';
import bannerAguaSanitaria from '../../assets/landing-page/produtos-limpeza/agua-sanitaria.png';
import bannerAguaSanitariaMobile from '../../assets/landing-page/produtos-limpeza/agua-sanitaria-mobile-1.png';
import bannerBompack from '../../assets/landing-page/produtos-limpeza/bompack.png';
import bannerBompackMobile from '../../assets/landing-page/produtos-limpeza/bompack-mobile.png';
import bannerCloro from '../../assets/landing-page/produtos-limpeza/cloro-jasmim.png';
import bannerCloroMobile from '../../assets/landing-page/produtos-limpeza/cloro-jasmim-mobile.png';

const DEPARTAMENTO_ID = 17;

export default {
  mixins: [gridMixin],
  data() {
    return {
      headerMaterial,
      query: `disponivel is true and departamento.id = ${DEPARTAMENTO_ID} order by random`,
      queryPapel: 'grupo.id = 134 and disponivel is true order by relevancia',
      queryProdutosLimpeza: 'grupo.id = 82 and disponivel is true order by random',
      bannerCadastroText: 'Cadastre-se agora e começe a comprar os produtos de limpeza para sua empresa',
      banners: [],
      bannersDuplos: [],
    };
  },
  computed: {
    height() {
      if (this.isMobile) {
        return '300px';
      }
      return '360px';
    },
  },
  methods: {
    onClickBannerCadastro() {
      this.$router.push({
        name: 'public.criar-conta',
      });
    },
  },
  created() {
    this.banners = [
      {
        foto: this.getGridImg(bannerAguaSanitaria, bannerAguaSanitariaMobile),
        link: '/oferta/11455',
      },
      {
        foto: this.getGridImg(bannerBompack, bannerBompackMobile),
        link: '/oferta/18443',
      },
      {
        foto: this.getGridImg(bannerCloro, bannerCloroMobile),
        link: '/oferta/11458',
      },
    ];
    this.bannersDuplos = [
      {
        foto: this.getGridImg(bannerDuploAlcool, bannerDuploAlcoolMobile),
        link: '/q/c/231',
      },
      {
        foto: this.getGridImg(bannerDuploAguaSanitaria, bannerDuploAguaSanitariaMobile),
        link: '/q/c/227',
      },
    ];
  },
  components: {
    pxHomePageBlock: HomePageBlock,
    pxHomeBannerCard: HomeBannerCard,
    pxLandingPageBanners: LandingPageBanners,
    pxCapa: Capa,
    pxVantagens: Vantagens,
    pxComoFunciona: ComoFunciona,
    pxFaqLandingPage: FaqLandingPage,
    pxRdDuvidaFormSubscription: RdDuvidaFormSubscription,
    PxBannerCadastroEmail,
    pxLandingPageWhatsapp: LandingPageWhatsapp,
  },
};
</script>
